.multi-action {
  display: inline-block;
  position: absolute;
  left: 50%;
}
  
.action-button {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 0;
  outline: 0;
  background: #f44336;
  font-size: 24px;
  color: white;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: all .3s;
}
  
.actions {
  position: absolute;
  list-style: none inside none;
  margin: 0 0 0 0;
  padding: 0;
  width: auto;
  float: left;
  background-color: transparent;
  top: 8px;
  left: 8px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  li {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin: 0;
    background: #212121;
    color: #ffffff;
    transition: all .3s;
    transform: scale(.3);
    &:nth-child(1) {background: #ff9800;}
    &:nth-child(2) {background: #2196F3;}
    &:nth-child(3) {background: #4CAF50;}
    &:nth-child(4) {background: #E91E63;}
    &:nth-child(5) {background: #4CAF50;}
    a {
      background: inherit;
      color: inherit;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
    }
    &:active {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
  
.action-button {
  &.active {
    box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19), 0 12px 15px 0 rgba(0, 0, 0, 0.24);
  }
  &.active ~ .actions {
    li {
      transition: all .3s;
      transform: scale(1);
      &:nth-child(1) {margin-top: -80px; margin-left: 0px;}
      &:nth-child(2) {margin-top: -60px; margin-left: -51px;}
      &:nth-child(3) {margin-top: -10px; margin-left: -75px;}
      &:nth-child(4) {margin-top: 40px; margin-left: -76px;}
      &:nth-child(5) {margin-top: 80px; margin-left: -35px;}
    }
  }
}
  
.action-button span {
  transition: all .3s;
}
  
.action-button {
  &.active:not(.no-rotate) span {
    transform: scale(1.5) rotate(-45deg);
  }
}
  
.tooltip-floating-button {
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the list item */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  color: white;
}
    
.actions li:hover .tooltip-floating-button {
  display: block;
}


.container-pd-search {
  position: relative;
}

.pd-search {
  width: 60%;
  height: auto;
  border-radius: 4rem;
}

.search-pd__box {
  float: left;
  width: 0;
  height: 2rem;
  background: none;
  outline: none;
  border: none;
  position: relative;
  opacity: 1;
  transition: all .75s ease-in;
  cursor: pointer;
  width: 80%;
  padding: 0 1rem;
}

.search-pd__icon {
  box-sizing: border-box;
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  color: #fa983a;
  transition: all .25s ease-in;
  padding: .3rem;
  border-radius: 50%;
}

// .pd-search:hover > .search-pd__box {
//   width: 80%;
//   padding: 0 1rem;
// }

/* For Chrome, Edge, and Safari */
.pd-questions, .question-list, .pd-body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}

.pd-questions::-webkit-scrollbar, .question-list::-webkit-scrollbar, .pd-body::-webkit-scrollbar {
display: none; /* Safari and Chrome */
}

.question-list {
  max-height: 200px;
  overflow: scroll;
}

.pd-body {
  min-height: 250px;
  max-height: 500px;
  overflow-y: scroll;
  background-color: aliceblue;
}

.question-container {
  position: absolute;
  bottom: 25px;
}