//
// _helper.scss
//

// Font Family
.font-family-secondary {
    font-family: $font-family-secondary;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

// Social

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 2px);
    display: block;
    border: 1px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
    transition: all 0.4s;

    &:hover {
        color: $gray-600;
        background-color: $gray-200;
    }
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

// overlay

.bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: $black;
}
.container-fluid {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

// alert

.alert-dismissible {
    .btn-close {
        font-size: 10px;
        padding: $alert-padding-y * 1.4 $alert-padding-x;
        // background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }
}

.chartjs-chart {
    max-height: 355px;
}

.modal-right {
    padding-right: 0 !important;
  
    .modal-dialog {
      margin: 0 auto;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
      max-width: 400px;
    }
  
    .modal-content {
      min-height: 100%;
    }
  
    .modal-header {
      height: 100px;
  
    //   @include respond-below(xl) {
    //     height: 90px;
    //   }
  
    //   @include respond-below(lg) {
    //     height: 80px;
    //   }
  
    //   @include respond-below(sm) {
    //     height: 70px;
    //   }
    }
  
    .modal-footer {
      justify-content: center;
    }
  
    .modal.fade .modal-dialog {
      transform: translate(25%, 0) !important;
    }
  
    .modal.show .modal-dialog {
      transform: translate(0, 0) !important;
    }
  }

  .wizard-order {
    margin-left: 30px;
    position: relative;
    
    .v-line {
      width: 1px;
      background-color: #ccc;
      position: absolute;
      margin-left: -13px;
      margin-top: 20px;
      height: 95%;
    }
    .step {
      padding: 15px 0;
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: lightgrey;
        position: absolute;
        margin-left: -20px;
        // top: 17px;
      }
    }



    .step.completed:before {
        content: "\f00c"; /* Font Awesome check icon */
        font-family: "Font Awesome 6 Free"; /* Ensure Font Awesome font is applied */
        font-weight: 900;
        color: white; /* Color of the check mark */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px; /* Adjust the size of the checkmark */
        background-color: #00cc66;
    }

    .step.ongoing:before {
        content: "\f111"; /* Font Awesome check icon */
        font-family: "Font Awesome 6 Free"; /* Ensure Font Awesome font is applied */
        background-color: yellowgreen;
        color: yellowgreen;
    }
  }