.node-style {
  background-color: #FFF;
  font-size: medium;
  border: 1px solid lightgreen;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
}

.draggable-field {
  width: 48%;
  border: 1px solid lightgreen;
  border-radius: 5px;
  text-align: center;
}

.picked-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgreen;
  border-radius: 1.5rem;
  padding: 0.3rem 0.6rem;
  font-size: xx-small;
}