input[type="range"] {
    -webkit-appearance: none;
    appearance: none; 
    width: 100%;
    border: 1px solid brown;
    height: 1rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1rem;
}
  
input[type="range"]::-webkit-slider-runnable-track {
    height: 1rem;
    border-radius: 1rem;
}
  
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 1rem;
    width: 1rem;
    background-color: white;
    border-radius: 50%;
    border: 1px solid hsl(335, 100%, 65%);
    box-shadow: 
  -50rem 0 5rem 20rem hsl(6, 97%, 71%), /* Red */
  -40rem 0 5rem 30rem hsl(36, 97%, 71%), /* Orange */
  -30rem 0 5rem 25rem hsl(56, 97%, 71%), /* Yellow */
  -20rem 0 5rem 25rem hsl(120, 97%, 71%); /* Green */

}

.jodit-toolbar__box {
    top: -20px !important;
}
  