.data-key {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: rgba(112, 112, 112, 1);
  max-width: 40% !important;
  flex-wrap: wrap;
}

.data-value {
  color: var(--Text-Primary, #3E3E3E);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00875rem;
  max-width: 50% !important;
  text-align: start;
  word-wrap: break-word; /* Break long words onto the next line */
  overflow-wrap: break-word; /* Break long words onto the next line (modern alternative) */
  white-space: normal; /* Allow text to wrap onto the next line */
  word-break: break-all;
}

.section-heading {
  // display: flex;
  padding: 0.4rem;
  justify-content: center;
  border-radius: 0.25rem;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.00875rem;
}

.assigee-dropdown{
  div {
    select{
      border: none;
      padding: 0 35px;
      margin: 0;
    }
  }
}

.clickable {
  cursor: pointer;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.common-card-style {
  border-radius: 0.25rem;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15) !important;
}

.rekyc-input {
  margin-right: 5px;
}

.sub-module-border {
  border: 1px solid green;
  border-radius: 5px;
  .sub-module-heading {
    position: relative;
    top: -10px;
    background: white;
    margin-left: 10px;
  }
}

.edited-permissions {
  color: red !important;
}

.addtional-doc-button-remove {
  position: relative;
  margin-top: 8px;
  right: 80px !important;
}

.jodit-status-bar {
  display: none !important;
}

.jodit-container {
  border: none !important;
}

.jodit-react-container {
  height: 100% !important;
}


// Figma-details styles

.details-card {

  box-shadow: 0px 4px 8px 0px #00000026;
  border: 1px solid $gray-350;
  border-radius: 8px;

  .card-header, .accordion-header {
    background: transparent;

    // font-family: Kanit;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0%;

  }
}

.details-table {
  // margin-bottom: 2.5rem;
  --bs-table-border-color: #C3C3C3;

  thead > tr > * {
    background-color: $blue-100;
    text-align: center;
    // font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 20.8px;
    letter-spacing: 0%;
    text-align: center;

  }

  &.table-hover {
    cursor: pointer;
  }

  &.table-striped {

    // tbody {
    //   -ms-overflow-style: none;  /* Internet Explorer 10+ */
    //   scrollbar-width: none;     /* Firefox */
    // }
  
    // tbody::-webkit-scrollbar {
    //   display: none; /* Safari and Chrome */
    // }

    tbody > tr:nth-of-type(even) > * {
      background-color: $gray-50;
    }
  
    tbody > tr:nth-of-type(odd) > * {
      --bs-table-bg-type: '';
    }

    tbody > tr > td {
      max-width: 100%;
      word-wrap: break-word; /* Break long words onto the next line */
      overflow-wrap: break-word; /* Break long words onto the next line (modern alternative) */
      white-space: normal; /* Allow text to wrap onto the next line */
      word-break: break-all;
    }

    tbody > tr > * {
      height: 35px;
      padding: 0.35rem 0.75rem;
    }

    tbody > tr > td > .action-fields {
      display: flex;
      justify-content: center;
    }

    tbody > tr {
      &.active {
        * {
          background-color: $primary;
          color: #FFF;
          --bs-table-hover-bg: '';
        }
      }
    }

    &.scrollable {
      tbody {
        display: block;
        max-height: 300px;
        overflow: auto;
      }
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
      }
    }

  }

  tbody > tr> td:nth-child(2) {
    text-align: center;
  }

  select {
    // appearance: none; /* Removes default OS-specific styles */
    // -webkit-appearance: none; /* Safari & Chrome */
    // -moz-appearance: none; /* Firefox */
    background: none; 
    border: none;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    width: 100%;
    text-align: center;

    option {
      background: #FFF;
      color: black;
      padding: 10px;
      font-size: 16px;
      border: 1px solid $gray-50;
      border-radius: 5px;
    }

    option:checked {
      background: $primary; /* Highlight the selected option */
      color: #FFF;
    }

    option:hover {
      background: $secondary;
      color: #FFF;
    }
  }

}