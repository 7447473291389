.text-input, .dropdown {
  
  .heading {
    color: var(--Label-Primary, #707070);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;

    span {
      color: red;
    }
  }

  ::placeholder {
    color: var(--Text-Secondary, #B0B0B0);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    /* H3/16PX/400 */
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: var(--Text-Secondary, #B0B0B0);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    /* H3/16PX/400 */
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
  }

  .input-group {
    
    input {
      border-color: hsl(0, 0%, 80%);
    }

    input:focus {
      // border: none;
      box-shadow: none;
      border: 1px solid lightgreen;
    }
  }
}
  
  
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.invalid-feedback, .valid-feedback {
  display: block !important;
}