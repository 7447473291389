.custom-uncontrolled-dropdown {

    .custom-uncontrolled-dropdown-heading {
        background-color: transparent;
        color: $primary;
        padding: 0 !important;
        border: none;
        box-shadow: none;
        // text-decoration: underline;
    }

    .custom-uncontrolled-dropdown-menu {
        max-height: 200px;
        overflow-y: scroll;
        // -ms-overflow-style: none; /* Internet Explorer 10+ */
        // scrollbar-width: none; /* Firefox */

        div {
            padding: 0.1rem;

            input {
                width: 100%;
                padding: 0.5rem;
                box-sizing: border-box;
                border: none;
            }
        }
    }

    // .custom-uncontrolled-dropdown-menu::-webkit-scrollbar {
    //     display: none; /* Safari and Chrome */
    // }
}